import React from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import Login from './components/Login';
import Registration from './components/Registration';
import Dashboard from './components/Dashboard';
import Notes from './pages/Notes';
import ClientInput from './pages/ClientInput';
import Insights from './pages/Insights';
import SettingsMenu from './components/SettingsMenu';
import FillQuestionnaire from './components/FillQuestionnaire';
import NotFound from './components/NotFound';
import {AuthProvider, useAuth} from './context/AuthContext';
import {PatientProvider} from './context/PatientContext';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faTrash} from '@fortawesome/free-solid-svg-icons';

library.add(faTrash);

// Wrapper component to access context
const AuthenticatedRoutes = () => {
  const { RequireAuth } = useAuth();
  
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login"/>}/>
      <Route path="/login" element={<Login/>}/>
      <Route path="/register" element={<Registration/>}/>
      <Route path="/dashboard" element={
        <RequireAuth>
          <Dashboard/>
        </RequireAuth>
      }>
        <Route index element={<Navigate to="notes"/>}/>
        <Route path="notes" element={<Notes/>}/>
        <Route path="clientinput" element={<ClientInput/>}/>
        <Route path="insights" element={<Insights/>}/>
        <Route path="settings" element={<SettingsMenu/>}/>
      </Route>
      <Route path="/api/fill-questionnaire/:token/:patient_id" element={<FillQuestionnaire/>}/>
      <Route path="*" element={<NotFound/>}/>
    </Routes>
  );
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <PatientProvider>
          <AuthenticatedRoutes />
        </PatientProvider>
      </Router>
    </AuthProvider>
  );
}

export default App;
