import React, {useState} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import {sendPasswordResetEmail} from 'firebase/auth';
import {TextField, Button, Typography, Box} from '@mui/material';
import {auth} from '../config/firebaseConfig';
import styles from './Login.module.css';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useAuth } from '../context/AuthContext';


const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [forgotEmail, setForgotEmail] = useState('');
    const [error, setError] = useState(null);
    const [resetMessage, setResetMessage] = useState(null);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [localError, setLocalError] = useState(null);
    const {login, error: authError, loading} = useAuth();
    const navigate = useNavigate();

    const validateInputs = () => {
        if (!email || !password) {
            setLocalError('Please enter both email and password.');
            return false;
        }
        if (!/\S+@\S+\.\S+/.test(email)) {
            setLocalError('Please enter a valid email address.');
            return false;
        }
        return true;
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        if (!validateInputs() || loading) return;

        setLocalError(null);
        try {
            await login(email, password);
            navigate('/dashboard');
        } catch (error) {
            console.error('Error during login:', error);
            if (error.code === 'auth/invalid-credential') {
                setLocalError('Invalid email or password');
            } else if (error.code === 'auth/too-many-requests') {
                setLocalError('Too many login attempts. Please try again later.');
            } else {
                setLocalError('Failed to log in. Please check your credentials.');
            }
        }
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        if (!forgotEmail) {
            setResetMessage('Please enter your email address.');
            return;
        }
        try {
            await sendPasswordResetEmail(auth, forgotEmail);
            setResetMessage('Password reset email sent.');
        } catch (error) {
            console.error('Reset password failed:', error);
            setResetMessage('Failed to send reset email. Please try again.');
        }
    };

    const toggleForgotPassword = () => {
        setShowForgotPassword(!showForgotPassword);
        setError(null);
        setResetMessage(null);
    };

    return (
        <Box className={styles.loginPage}>
            <Box className={styles.loginMainContent}>
                <Box className={styles.loginContainer} style={{display: showForgotPassword ? 'none' : 'block'}}>
                    <Box className={styles.logoContainer}>
                        <img
                            src="https://firebasestorage.googleapis.com/v0/b/psyntel.appspot.com/o/psyntel_concept.png?alt=media&token=53b59fe7-acc5-4260-9881-e6eb28bc5af0"
                            alt="Psyntel Logo"
                            className={styles.logo}
                        />
                    </Box>
                    <form onSubmit={handleLogin}>
                        <TextField
                            type="email"
                            placeholder="Email"
                            className={styles.formControl}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                            variant="outlined"
                        />
                        <TextField
                            type="password"
                            placeholder="Password"
                            className={styles.formControl}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            fullWidth
                            variant="outlined"
                        />
                        <Button type="submit" className={styles.loginButton} fullWidth>
                            Login
                        </Button>
                    </form>
                    {error && <Typography className={styles.errorMessage}>{error}</Typography>}
                    <Box className={styles.loginFooter}>
                        <Typography>
                            <Link to="#" onClick={toggleForgotPassword} className={styles.hyperlinkBlue}>Forgot
                                password</Link>
                        </Typography>
                        <Typography>
                            New to Psyntel?<br/>
                            Sign up <Link to="/register" className={styles.hyperlinkBlue}>here</Link>
                        </Typography>
                    </Box>
                </Box>

                <Box className={styles.loginContainer} style={{display: showForgotPassword ? 'block' : 'none'}}>
                    <Box className={styles.logoContainer}>
                        <img
                            src="https://firebasestorage.googleapis.com/v0/b/psyntel.appspot.com/o/psyntel_concept.png?alt=media&token=53b59fe7-acc5-4260-9881-e6eb28bc5af0"
                            alt="Psyntel Logo"
                            className={styles.logo}
                        />
                    </Box>
                    <form onSubmit={handleResetPassword}>
                        <TextField
                            type="email"
                            placeholder="Email"
                            className={styles.formControl}
                            value={forgotEmail}
                            onChange={(e) => setForgotEmail(e.target.value)}
                            fullWidth
                            variant="outlined"
                        />
                        <Button type="submit" className={styles.loginButton} fullWidth>
                            Send Reset Email
                        </Button>
                    </form>
                    {resetMessage && (
                        <Typography
                            className={resetMessage.includes('sent') ? styles.successMessage : styles.errorMessage}>
                            {resetMessage}
                        </Typography>
                    )}
                    <Box className={styles.loginFooter}>
                        <Typography>
                            <Link to="#" onClick={toggleForgotPassword} className={styles.hyperlinkBlue}>Back to
                                login</Link>
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <loginFooter2 className={styles.loginFooter2}>
                <Box className={styles.footerLogo}>
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/psyntel.appspot.com/o/psyntel_logo-removebg-preview.png?alt=media&token=c371a13e-f2b7-4887-b168-4470dcb7ef8a"
                        alt="Psyntel Logo"
                        style={{height: '40px'}}
                    />
                </Box>
                <Box className={styles.footerLinks}>
                    <Typography>Follow us</Typography>
                    <a href="https://www.linkedin.com/company/psyntel/" target="_blank" rel="noopener noreferrer"
                       className={styles.linkedInLink}>
                        <LinkedInIcon className={styles.linkedinIcon}/> LinkedIn
                    </a>
                </Box>
                <Box className={styles.termsPrivacy}>
                    <a href="https://psyntel.com/#termsofservice" className={styles.hyperlink}>Terms of Service</a>
                    <a href="https://psyntel.com/#privacypolicy" className={styles.hyperlink}>Privacy Policy</a>
                </Box>
            </loginFooter2>
        </Box>
    );
};

export default Login;