import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import styles from './TopBanner.module.css';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import WarningIcon from '@mui/icons-material/Warning';
import {Menu, MenuItem, IconButton, Typography, Chip} from '@mui/material';
import { getAuth } from "firebase/auth";

const TrialButton = ({ daysRemaining, onSubscribe }) => {
  const isUrgent = daysRemaining <= 3;
  const isWarning = daysRemaining <= 7;

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginRight: '18px' }}>
      {isUrgent && (
        <Chip
          icon={<WarningIcon style={{ color: '#ef4444' }} />}
          label="Trial ending soon!"
          size="small"
          style={{
            backgroundColor: '#fee2e2',
            color: '#ef4444',
            fontWeight: 500
          }}
        />
      )}

      <button
        onClick={onSubscribe}
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          padding: '8px 16px',
          borderRadius: '9999px',
          border: 'none',
          cursor: 'pointer',
          transition: 'all 0.3s',
          backgroundColor: isUrgent ? '#ef4444' : isWarning ? '#f59e0b' : '#3b82f6',
          color: 'white',
          fontSize: '14px',
        }}
        onMouseEnter={e => {
          e.currentTarget.style.transform = 'scale(1.05)';
          e.currentTarget.style.backgroundColor = isUrgent ? '#dc2626' : isWarning ? '#d97706' : '#2563eb';
        }}
        onMouseLeave={e => {
          e.currentTarget.style.transform = 'scale(1)';
          e.currentTarget.style.backgroundColor = isUrgent ? '#ef4444' : isWarning ? '#f59e0b' : '#3b82f6';
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <TimerOutlinedIcon style={{ width: '16px', height: '16px' }} />
          <span style={{ fontWeight: 500 }}>{daysRemaining}</span>
          <span>days left in your trial</span>
        </div>

        <div style={{
          height: '16px',
          width: '1px',
          backgroundColor: 'rgba(255, 255, 255, 0.3)',
          margin: '0 8px'
        }} />

        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <span style={{ fontWeight: 500 }}>Subscribe here</span>
        </div>
      </button>
    </div>
  );
};

const TopBanner = ({userName, toggleSidebar, activeView, setActiveView, onLogout}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [subscriptionStatus, setSubscriptionStatus] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const checkSubscription = async () => {
            try {
                // Get the current user token first
                const token = await getAuth().currentUser.getIdToken();
                
                // Include the token in the request headers
                const response = await fetch('/api/check-subscription', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                
                const data = await response.json();
                setSubscriptionStatus(data);
            } catch (error) {
                console.error('Error checking subscription:', error);
            } finally {
                setLoading(false);
            }
        };

        checkSubscription();
    }, []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleClose();
        onLogout();
    };

    const handleSettings = () => {
        handleClose();
        setActiveView('settings');
        navigate('/dashboard/settings');
    };

    const handleSubscribe = async () => {
        try {
            // Get the current user token
            const token = await getAuth().currentUser.getIdToken();
            
            // Call the backend to create a checkout session
            const response = await fetch('/api/create-checkout-session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            
            if (!response.ok) {
                throw new Error('Failed to create checkout session');
            }
            
            const { url } = await response.json();
            
            // Redirect to checkout
            window.location.href = url;
        } catch (error) {
            console.error('Error creating checkout session:', error);
            // You can add error handling UI here
        }
    };

    return (
        <div className={styles.topBannerContainer}>
            <div className={styles.topBanner}>
                <div className={styles.leftSection}>
                    <button className={styles.hamburgerMenu} onClick={toggleSidebar}>☰</button>
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/psyntel.appspot.com/o/psyntel_only.png?alt=media&token=6f439213-010b-4545-9235-07b97991bfac"
                        alt="Psyntel Logo" className={styles.logo}
                    />
                    <nav className={styles.navSection}>
                        <ul className={styles.navList}>
                            <li className={styles.navItem}>
                                <button
                                    className={`${styles.navLink} ${activeView === 'notes' ? styles.navLinkActive : ''}`}
                                    onClick={() => setActiveView('notes')}
                                >
                                    Notes
                                </button>
                            </li>
                            <li className={styles.navItem}>
                                <button
                                    className={`${styles.navLink} ${activeView === 'clientinput' ? styles.navLinkActive : ''}`}
                                    onClick={() => setActiveView('clientinput')}
                                >
                                    Client Input
                                </button>
                            </li>
                            <li className={styles.navItem}>
                                <button
                                    className={`${styles.navLink} ${activeView === 'insights' ? styles.navLinkActive : ''}`}
                                    onClick={() => setActiveView('insights')}
                                >
                                    Insights
                                    <AutoAwesomeOutlinedIcon className={styles.navLinkIcon}/>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className={styles.centerLogo}>
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/psyntel.appspot.com/o/psyntel_logo-removebg-preview.png?alt=media&token=c371a13e-f2b7-4887-b168-4470dcb7ef8a"
                        alt="Psyntel Centered Logo"
                        className={styles.centeredLogo}
                    />
                </div>
                <div className={styles.rightSection}>
                    {!loading && subscriptionStatus?.trial_end && (
                        <TrialButton
                            daysRemaining={Math.ceil((subscriptionStatus.trial_end * 1000 - Date.now()) / (1000 * 60 * 60 * 24))}
                            onSubscribe={handleSubscribe}
                        />
                    )}
                    <Typography variant="body1" className={styles.userName}>
                        {userName}
                    </Typography>
                    <IconButton onClick={handleClick} color="inherit" className={styles.userMenuButton}>
                        <AccountCircleIcon/>
                        <ExpandMoreIcon/>
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleSettings}>Settings</MenuItem>
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                </div>
            </div>
        </div>
    );
};

export default TopBanner;
