import React, {useState, useEffect} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import {getAuth, createUserWithEmailAndPassword, updateProfile} from 'firebase/auth';
import {getFirestore, doc, setDoc} from 'firebase/firestore';
import {Snackbar, Alert} from '@mui/material';
import {
    TextField,
    Button,
    FormControlLabel,
    Checkbox,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    ListItemText,
    OutlinedInput,
    Box,
    Typography,
} from '@mui/material';
import styles from './Registration.module.css';

const PROFESSIONS = [
    'Psychiatrist',
    'Psychologist',
    'Licensed Clinical Social Worker (LCSW)',
    'Licensed Professional Counselor (LPC)',
    'Marriage and Family Therapist (MFT)',
    'Mental Health Nurse Practitioner',
    'Other'
];

const THERAPY_STYLES = [
    'Cognitive Behavioral Therapy (CBT)',
    'Dialectical Behavior Therapy (DBT)',
    'Psychodynamic Therapy',
    'Acceptance and Commitment Therapy (ACT)',
    'Existential Therapy',
    'Eye Movement Desensitization and Reprocessing (EMDR)',
    'Mindfulness-Based Cognitive Therapy (MBCT)',
    'Gestalt Therapy',
    'Motivational Interviewing',
    'Person-Centered Therapy',
    'Eclectic Therapy',
    'Reality Therapy',
    'Narrative Therapy',
    'Emotionally Focused Therapy (EFT)'
];

const Registration = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [profession, setProfession] = useState('');
    const [therapyStyles, setTherapyStyles] = useState([]);
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [attestProfessional, setAttestProfessional] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [passwordRequirements, setPasswordRequirements] = useState({
        length: false,
        uppercaseLowercase: false,
        number: false,
        specialChar: false
    });
    const navigate = useNavigate();

    useEffect(() => {
        checkPasswordRequirements(password);
    }, [password]);

    const checkPasswordRequirements = (password) => {
        setPasswordRequirements({
            length: password.length >= 8,
            uppercaseLowercase: /(?=.*[a-z])(?=.*[A-Z])/.test(password),
            number: /\d/.test(password),
            specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password)
        });
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        if (!agreeTerms || !attestProfessional) {
            setError('You must agree to the terms and attest to being a mental health professional.');
            return;
        }

        try {
            // Create Firebase user
            const auth = getAuth();
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Update user profile
            await updateProfile(user, {
                displayName: `${firstName} ${lastName}`
            });

            // Save additional user data to Firestore
            const db = getFirestore();
            await setDoc(doc(db, 'Psyntel', user.uid, 'user_info', 'info'), {
                firstName,
                lastName,
                email,
                profession,
                therapyStyles,
                createdAt: new Date(),
            }, { merge: true });

            // Create Stripe customer and subscription
            const idToken = await user.getIdToken();
            const response = await fetch('/api/subscription/create-customer', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify({
                    email,
                    firstName,
                    lastName
                })
            });
            
            if (!response.ok) {
                throw new Error('Failed to create Stripe customer');
            }
            
            const stripeData = await response.json();
            console.log('Stripe subscription created:', stripeData);
            
            // Set success message and show snackbar
            setSuccessMessage('Success! Your 30-day free trial has started. Redirecting to login...');
            setOpenSnackbar(true);
            
            // Redirect to login page after a short delay
            setTimeout(() => {
                navigate('/login');
            }, 3000);
        } catch (error) {
            console.error('Stripe error:', error);
            setError('Your account was created but we had trouble setting up your trial. Please contact support.');
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const isFormValid = agreeTerms && attestProfessional &&
        Object.values(passwordRequirements).every(req => req) &&
        email && firstName && lastName && profession && therapyStyles.length > 0;

    return (
        <div className={styles['R-registration-wrapper']}>

            <Box className={styles['R-registration-page']}>
                <Box className={styles['R-main-content']}>
                    <Box className={styles['R-container']}>
                        <Box className={styles['R-header']}>
                            <img
                                src="https://firebasestorage.googleapis.com/v0/b/psyntel.appspot.com/o/psyntel_concept.png?alt=media&token=53b59fe7-acc5-4260-9881-e6eb28bc5af0"
                                alt="Psyntel Logo" className={styles['R-logo']}/>
                        </Box>
                        <Box className={styles['R-trial-info']}>
                            <Typography variant="h6" gutterBottom>Start Your Free Trial</Typography>
                            <Typography variant="body2" color="text.secondary">
                                ✓ 30-day free trial with full access
                                <br/>
                                ✓ No credit card required during trial
                                <br/>
                                ✓ Cancel anytime during trial period
                            </Typography>
                        </Box>
                        <form onSubmit={handleRegister}>
                            <TextField
                                label="First Name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                required
                                fullWidth
                                margin="normal"
                                className={styles['R-form-control']}
                            />
                            <TextField
                                label="Last Name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                required
                                fullWidth
                                margin="normal"
                                className={styles['R-form-control']}
                            />
                            <TextField
                                label="Email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                fullWidth
                                margin="normal"
                                className={styles['R-form-control']}
                            />
                            <TextField
                                label="Password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                fullWidth
                                margin="normal"
                                className={styles['R-form-control']}
                            />
                            <Box className={styles['R-password-requirements']}>
                                <Typography variant="body2">Your password must contain:</Typography>
                                <ul>
                                    <li className={passwordRequirements.length ? styles.met : ''}>At least 8
                                        characters
                                    </li>
                                    <li className={passwordRequirements.uppercaseLowercase ? styles.met : ''}>At least 1
                                        upper and lowercase letter
                                    </li>
                                    <li className={passwordRequirements.number ? styles.met : ''}>At least 1 number</li>
                                    <li className={passwordRequirements.specialChar ? styles.met : ''}>At least 1
                                        special character
                                    </li>
                                </ul>
                            </Box>
                            <FormControl fullWidth margin="normal" className={styles['R-form-control']}>
                                <InputLabel>Profession</InputLabel>
                                <Select
                                    value={profession}
                                    onChange={(e) => setProfession(e.target.value)}
                                    required
                                >
                                    {PROFESSIONS.map((prof) => (
                                        <MenuItem key={prof} value={prof}>{prof}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth margin="normal" className={styles['R-form-control']}>
                                <InputLabel>Therapy Styles</InputLabel>
                                <Select
                                    multiple
                                    value={therapyStyles}
                                    onChange={(e) => setTherapyStyles(e.target.value)}
                                    input={<OutlinedInput label="Therapy Styles"/>}
                                    renderValue={(selected) => selected.join(', ')}
                                >
                                    {THERAPY_STYLES.map((style) => (
                                        <MenuItem key={style} value={style}>
                                            <Checkbox checked={therapyStyles.indexOf(style) > -1}/>
                                            <ListItemText primary={style}/>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControlLabel
                                control={<Checkbox checked={agreeTerms}
                                                   onChange={(e) => setAgreeTerms(e.target.checked)}/>}
                                label={<>I agree to the Psyntel Inc. <a href="https://psyntel.com/#termsofservice"
                                                                        target="_blank" rel="noopener noreferrer"
                                                                        className={styles['R-hyperlink-blue']}>Terms of
                                    Service</a> and <a href="https://psyntel.com/#privacypolicy" target="_blank"
                                                       rel="noopener noreferrer" className={styles['R-hyperlink-blue']}>Privacy
                                    Policy</a>.</>}
                                className={styles['R-form-check']}
                            />
                            <FormControlLabel
                                control={<Checkbox checked={attestProfessional}
                                                   onChange={(e) => setAttestProfessional(e.target.checked)}/>}
                                label="I attest that I am a mental health professional or mental health professional-in-training, and I understand that Psyntel is not intended for use by members of the general public."
                                className={styles['R-form-check']}
                            />
                            {error && <Typography color="error">{error}</Typography>}


                            <Box className={styles['R-trial-note']}>
                                <Typography variant="body2" color="text.secondary" align="center">
                                    By clicking "Start Free Trial", you'll get immediate access to all features.
                                    We'll remind you before your trial ends - no automatic charges.
                                </Typography>
                            </Box>

                            <Button type="submit" variant="contained" color="primary" fullWidth disabled={!isFormValid}
                                    className={styles['R-btn-primary']}>
                                Start Free Trial
                            </Button>
                        </form>
                        <Box className={styles['R-login-footer']}>
                            <Typography>Already have an account? <Link to="/login"
                                                                       className={styles['R-hyperlink-blue']}>Login</Link></Typography>
                        </Box>
                    </Box>
                </Box>
                <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                    <Alert onClose={handleCloseSnackbar} severity="success" sx={{width: '100%'}}>
                        {successMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </div>
    );
};

export default Registration;
