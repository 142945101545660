import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth } from '../config/firebaseConfig';
import SubscriptionRequired from '../components/SubscriptionRequired';
import { Navigate, useLocation } from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [checkingSubscription, setCheckingSubscription] = useState(false);

  const isLoading = loading || checkingSubscription;

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
        checkSubscription(user);
      } else {
        setCurrentUser(null);
        setSubscriptionStatus(null);
      }
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const checkSubscription = async (user) => {
    if (!user) return;
    
    setCheckingSubscription(true);
    try {
      const token = await user.getIdToken();
      
      const response = await fetch('/api/check-subscription', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (!response.ok) {
        console.error('Subscription check failed:', await response.text());
        setSubscriptionStatus({
          state: 'expired',
          status: 'expired'
        });
        return;
      }
      
      const data = await response.json();
      
      setSubscriptionStatus({
        state: data.status,
        status: data.status === 'canceled' ? 'canceled' : 
                data.status === 'expired' ? 'expired' : data.status
      });
    } catch (error) {
      console.error('Error checking subscription:', error);
      setSubscriptionStatus({
        state: 'expired',
        status: 'expired',
        error: error.message
      });
    } finally {
      setCheckingSubscription(false);
    }
  };

  const login = async (email, password) => {
    setLoading(true);
    setError(null);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      
      const idToken = await userCredential.user.getIdToken();
      const response = await fetch('/api/verifyToken', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ idToken }),
      });

      if (!response.ok) {
        throw new Error('Failed to verify token');
      }
      
      setCurrentUser(userCredential.user);
      
      await checkSubscription(userCredential.user);
      
      return userCredential.user;
    } catch (error) {
      console.error("Login error:", error.code, error.message);
      setError(error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    setError(null);
    try {
      await signOut(auth);
      setCurrentUser(null);
      setSubscriptionStatus(null);
    } catch (error) {
      console.error("Logout error:", error.code, error.message);
      setError(error.message);
      throw error;
    }
  };

  const hasValidSubscription = () => {
    return (
      subscriptionStatus &&
      (subscriptionStatus.state === 'active' || subscriptionStatus.state === 'trialing')
    );
  };

  const RequireAuth = ({ children }) => {
    const location = useLocation();
    
    if (isLoading) {
      return (
        <div className="flex items-center justify-center min-h-screen">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      );
    }
    
    if (!currentUser) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
    
    return (
      <>
        {children}
        {subscriptionStatus && !hasValidSubscription() && (
          <SubscriptionRequired status={subscriptionStatus.status} />
        )}
      </>
    );
  };

  const value = {
    currentUser,
    user: currentUser,
    login,
    logout,
    error,
    loading: isLoading,
    subscriptionStatus,
    hasValidSubscription,
    checkSubscription,
    RequireAuth
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};