import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useAuth } from '../context/AuthContext';

const SubscriptionRequired = ({ status }) => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  // Define status message based on subscription status
  const statusMessage = status === 'expired' 
    ? "Your access has expired. Please subscribe to continue using Psyntel."
    : status === 'canceled'
      ? "Your access has expired. Please subscribe to continue using Psyntel."
      : "Your access has expired. Please subscribe to continue using Psyntel.";

  // Features list
  const features = [
    "Turn rough notes into SOAP or DAP formats instantly",
    "Build custom treatment plans in seconds",
    "Send & track client questionnaires",
    "Auto generate clinical insights & progress reports",
    "Brainstorm with our purpose-built chat interface",
    "Save & manage all session notes",
    "Compile discharge summaries",
    "Unlimited clients"
  ];

  // Handle subscription button click
  const handleSubscribe = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const token = await currentUser.getIdToken();
      
      const response = await fetch('/api/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Cache-Control': 'no-cache'
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to create checkout session');
      }
      
      const { url } = await response.json();
      window.location.href = url;
    } catch (error) {
      console.error('Error creating checkout session:', error);
      setError(error.message || 'Failed to process subscription request');
    } finally {
      setLoading(false);
    }
  };

  // Define inline styles
  const overlayStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(3px)',
    zIndex: 9999,
    padding: '16px'
  };

  const modalStyles = {
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
    maxWidth: '550px',
    width: '100%',
    padding: '30px',
    animation: 'fadeIn 0.3s ease-out'
  };

  const headerStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '24px',
    textAlign: 'center'
  };

  const titleStyles = {
    fontSize: '28px',
    fontWeight: 'bold',
    color: '#333',
    marginBottom: '8px'
  };

  const priceStyles = {
    fontSize: '32px',
    fontWeight: 'bold',
    color: '#2563eb',
    marginBottom: '4px'
  };

  const statusStyles = {
    fontSize: '16px',
    color: '#e11d48',
    marginTop: '16px',
    padding: '12px',
    backgroundColor: '#ffe4e6',
    borderRadius: '6px',
    marginBottom: '16px',
    textAlign: 'center'
  };

  const featuresContainerStyles = {
    backgroundColor: '#f9fafb',
    borderRadius: '8px',
    padding: '20px',
    marginBottom: '24px'
  };

  const featuresHeaderStyles = {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#333',
    marginBottom: '12px'
  };

  const featureListStyles = {
    listStyleType: 'none',
    padding: '0',
    margin: '0'
  };

  const featureItemStyles = {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    color: '#4b5563',
    padding: '8px 0'
  };

  const checkmarkStyles = {
    color: '#2563eb',
    marginRight: '10px',
    fontWeight: 'bold'
  };

  const buttonStyles = {
    width: '100%',
    backgroundColor: '#2563eb',
    color: 'white',
    fontWeight: 'bold',
    padding: '14px',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '18px',
    opacity: loading ? 0.7 : 1,
    transition: 'background-color 0.2s',
    ':hover': {
      backgroundColor: '#1d4ed8'
    }
  };

  const errorStyles = {
    backgroundColor: '#fee2e2',
    borderColor: '#ef4444',
    color: '#b91c1c',
    padding: '12px',
    borderRadius: '6px',
    marginBottom: '16px'
  };

  const footerTextStyles = {
    fontSize: '14px',
    color: '#9ca3af',
    marginTop: '20px',
    textAlign: 'center'
  };

  return ReactDOM.createPortal(
    <div style={overlayStyles}>
      <div style={modalStyles}>
        <div style={headerStyles}>
          <h2 style={titleStyles}>Subscription Required</h2>
          <div style={priceStyles}>$25/month</div>
        </div>
        
        {/* Status message */}
        <div style={statusStyles}>
          {statusMessage}
        </div>
        
        {/* Features list */}
        <div style={featuresContainerStyles}>
          <div style={featuresHeaderStyles}>Everything you need for efficient clinical practice:</div>
          <ul style={featureListStyles}>
            {features.map((feature, index) => (
              <li key={index} style={featureItemStyles}>
                <span style={checkmarkStyles}>✓</span> {feature}
              </li>
            ))}
          </ul>
        </div>
        
        {error && (
          <div style={errorStyles}>
            {error}
          </div>
        )}
        
        <button
          onClick={handleSubscribe}
          disabled={loading}
          style={buttonStyles}
        >
          {loading ? 'Processing...' : 'Subscribe'}
        </button>
        
        <p style={footerTextStyles}>
          Your account data will be kept for 30 days after expiration. After that, it will be permanently deleted.
        </p>
      </div>
    </div>,
    document.body
  );
};

export default SubscriptionRequired;
